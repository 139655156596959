import { CompareGapResponse } from "@/interfaces/responses/compare_gap";

export interface CompareResultResponse {
  data: CompareResultResponseData[];
}

export interface CompareResultResponseData {
  cvs: CompareGapResponse[];
  external_job_id: string;
  industry: string;
  job_id: string;
}

export interface CompareResult {
  compare_id: number;
  created_at: Date;
  deleted_at: Date;
  id: number;
  job_id: number;
  response: CompareResultResponse | CompareGapResponse;
  updated_at: Date;
  upload_id: number;
  user_id: number;
}

export function isCompareResultResponse(
  obj: any
): obj is CompareResultResponse {
  return "data" in obj;
}
