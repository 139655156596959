var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"page-wrapper"},[_c('div',{staticClass:"my-3 mb-5 d-flex align-center justify-space-between"},[_c('p',{staticClass:"section-main-heading mb-0"},[_vm._v("Analyzed Results")]),_c('Transition',{attrs:{"appear":"","enter-class":"m-right-enter-from","enter-active-class":"m-right-enter-active","enter-to-class":"m-right-enter-to","leave-class":"m-right-leave-from","leave-active-class":"m-right-leave-active","leave-to-class":"m-right-leave-to"}},[_c('v-btn',{staticClass:"base-btn match-btn px-sm-10 py-5 elevation-0",attrs:{"link":"","to":"/matching/new"}},[_vm._v(" New Analysis ")])],1)],1),_c('v-data-table',{attrs:{"loading":_vm.loading || !_vm.cv_job_analysis,"loader-height":"6","headers":_vm.match_table_headers,"items":_vm.cv_job_analysis.listing ? _vm.cv_job_analysis.listing.results : [],"server-items-length":_vm.cv_job_analysis.listing ? _vm.cv_job_analysis.listing.total : 0,"options":_vm.options,"footer-props":{
      itemsPerPageOptions: _vm.per_page_options
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
    var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.uploadDetails.uploaded_file_name",fn:function(ref){
    var item = ref.item;
    var value = ref.value;
return [(item.uploadDetails.upload_type === 'file')?[_vm._v(" "+_vm._s(_vm.format_file_name(value))+" ")]:[_vm._v(" text.txt ")]]}},{key:"item.created_at",fn:function(ref){
    var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.date_format(value))+" ")]}},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{staticClass:"view-icon",attrs:{"icon":""},on:{"click":function($event){return _vm.view_analyzed_comparison(item)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }