































































import Vue from "vue";
import { DataOptions, DataTableHeader } from "vuetify";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  ANALYZED_COMPARISONS,
  CV_JOB_ANALYSIS_PAYLOAD,
  CV_JOB_LOADING
} from "@/store/modules/cv_job_matching/constants";
import { format_file_name } from "@/utils/global";
import { date_format } from "@/utils/skill_extractor";
import { CompareGapWithUploads } from "@/interfaces/responses/compare_gap";
import { CvJobAnalysis } from "@/store/modules/cv_job_matching/interfaces";
import { isCompareResultResponse } from "@/interfaces/data_objects/compare_results";

export default Vue.extend({
  name: "CvJobListing",
  data() {
    return {
      match_table_headers: [
        {
          text: "#",
          sortable: false,
          value: "index",
          align: "start"
        },
        {
          text: "File Name",
          sortable: false,
          value: "uploadDetails.uploaded_file_name",
          align: "start"
        },
        {
          text: "Date",
          sortable: false,
          value: "created_at",
          align: "start"
        },
        {
          text: "Action",
          sortable: false,
          value: "action",
          align: "start"
        }
      ] as Array<DataTableHeader>,
      per_page_options: [10, 20, 30, 40],
      options: {} as DataOptions // Data table options
    };
  },
  watch: {
    // Watcher to run after every interaction in data table
    options: {
      handler() {
        this.process_listing_fetching();
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters("cv_job_matching", {
      loading: CV_JOB_LOADING,
      cv_job_analysis: CV_JOB_ANALYSIS_PAYLOAD
    })
  },
  methods: {
    ...mapMutations("cv_job_matching", {
      set_cv_job_analysis: CV_JOB_ANALYSIS_PAYLOAD
    }),
    date_format,
    format_file_name,
    ...mapActions("cv_job_matching", {
      get_analyzed_comparisons: ANALYZED_COMPARISONS
    }),
    async process_listing_fetching() {
      let item_per_page = this.options.itemsPerPage;
      let page_number = this.options.page - 1;
      await this.get_analyzed_comparisons({ item_per_page, page_number });
    },
    async view_analyzed_comparison(data: CompareGapWithUploads) {
      const payload: CvJobAnalysis = {
        cv_file: null,
        job_file: null,
        job_link: null,
        job_loading: false,
        cv_loading: false,
        job_response: data.jobDetails,
        cv_file_response: null,
        searched_cv: data.uploadDetails,
        listing: null,
        compare_gap_loading: false,
        compare_gap_response: isCompareResultResponse(data.response)
          ? data.response.data[0].cvs[0]
          : data.response,
        fetching_required: false
      };
      this.set_cv_job_analysis(payload);
      await this.$router.push("/matching/new/analysis");
    }
  }
});
